export const changeProspect = 'Change Prospect';
export const addProspect = 'Add Prospect';
export const prospectNotesAdm = 'Prospect Notes';
export const deleteProspect = 'Delete Prospect';
export const Configuration = 'Configuration';
export const maintenance = 'Maintenance'
export const addBatch = 'Add';
export const input = 'Input';
export const printBatch = 'Print';
export const deleteBatch = 'Delete';
export const distributeBatch = 'Distribute';
export const changeAddress = 'Change Address'
export const newsFeed = 'CAMS News Feed';
export const changeTransfer = 'Transfer';
export const financialTransfer = 'Transfer';
export const financialHolds = 'Holds';
export const studentNotes = 'Student Notes';
export const workstudySetup = 'Workstudy Setup';
export const awardReferrence = 'Award Reference';
export const schedule = 'Schedule';
export const officialTranscript = 'Official Transcript';
export const advisorTranscript = 'Advisors Transcript';
export const catalogMaintenance = 'Maintenance';
export const copyCatalog = 'Copy Catalog';
export const scheduleRegistration = 'Schedule Registration';
export const dataEntry = 'Data Entry';
export const dataRangeEntry = 'Date Range Entry';
export const studentEvaluation = 'Student Evaluation';
export const billingAuthorize = 'Authorize';
export const changeBillingMaintenace = 'Maintenance';
export const generalLegerPostAccess = 'General Ledger';
export const generalLegerPayableAccess = 'Accounts Payable';
export const billingRecreateAccess = 'General Ledger';
export const billingRecreatePayable = 'Accounts Payable';
export const changeCashierEntry = 'Cashier Entry'
export const change1098TReportAccess = '1098T Export'
export const studentPictureUpload = 'Student Picture Load'
export const facultyPictureLoadData = 'Faculty Picture Load'
export const addProspectActivity = 'Add Prospect Activity'
export const addStudentActivity = 'Add Student Activity'
export const statusChanges = 'Status Changes'
export const evaluationSetup = 'CRS Evaluation Setup';
export const transcriptBatches = 'Transcript Batches';
export const massUpdateStatus = 'Mass Update Status';
export const massAddAudit = 'Mass Add Audit';
export const mergeOffering = 'Merge Offering';
export const sap = 'SAP';
export const billingProcess = 'Billing Processes';
export const noShowRemove = 'No Show Remove';
export const r2t4 = 'R2T4';
export const latePaymentFees = 'Late Payment Fees';
export const degreeVerify = 'Degree Verify';
export const nysSiris = 'NYS SIRIS';
export const khedsExport = 'KHEDS Export';
export const texasStudentCensus = 'CBM0C1 - Student Census';
export const graduation = 'CBM009 - Graduation';
export const ceStudents = 'CBM00A - CE Students';
export const studentSchedule = 'CBM00S - Student Schedule';
export const censusStudentSchedule = 'CBM0CS - Census Student Schedule';
export const endSemStudents = 'CBM0E1 - End Sem Students';
export const CbmceClasses = 'CBM00C - CE Classes';
export const txFinAidSystem = 'TFADS - TX Fin Aid DB Sys';
export const successInit = 'CBM002 - Success Init';
export const faculty = 'CBM008 - Faculty';
export const User = 'User Administration';
export const role = 'Role Administration';
export const fpAdmin = 'FP Admin Role Access';
export const deleteStudent = 'Delete Students';
export const rebuildRepeats = 'Rebuild Repeats';
export const asnImport = 'ASN Import';
export const billingImport = 'Billing Import';
export const codExport = 'COD Export';
export const gainfulEmploymentExport = 'Gainful Employment Export';
export const powerfaids = 'Powerfaids';
export const nslcExport = 'NSC Export';
export const doctrackAutoload = 'Doctrack AutoLoad';
export const config = 'Configure';
export const currencyTp = 'Currency Types';
export const exRates = 'Exchange Rates';
export const customGrede = 'Custom Grade Entry';
export const userDefined = 'User Defined Field Setup';
export const risk = 'Risk Indicator Setup';
export const testScore = 'Test Score Table Setup';
export const emailTemp = 'Email Templates';
export const institutionalSAP = 'Institutional SAP';
export const governmentSAP = 'Governmental SAP';
export const gradeGroup = 'Grade Groups';
export const docTrack = 'DocTrack Setup';
export const stateReport = 'State Reports Lookup';
export const portalURL = 'Portal URL Ref';
export const termCalender = 'Term Calendar';
export const countrySet = 'County Setup';
export const locationSet = 'Location Setup';
export const gradeEntry = 'Grade Entry'
export const numberGradeEntry = 'Number Grade Entry'
export const authorizePortalGradesReg = 'Authorize Portal Grades'
export const single = 'Single';
export const packagesCriteria = 'Package Criteria';
export const awardMethodology = 'Award Methodology';
export const budget = 'Budgets';
export const lifeTimeLimits = 'Lifetime Limits';
export const FinancialPackage = 'Package';
export const degreeAuditSetup = 'Degree Audit Setup';
export const bulkProspectImport = 'Bulk Prospect Import';
export const testScoreMapping = 'Test Score Mapping';
export const applyTexas = 'Apply Texas Import';
export const testScoresImport = 'Test Scores Import';
export const prospectStudentImportLabel = 'Prospect/Student Import';
export const speedeCollegeTranscript = 'Speede College Transcript';
export const massStudentRegistration = 'Mass Student Registration';
export const codMessages = 'COD';
export const workStudyMaintenance = 'Work Study Maintenance';
export const yearlySetup = 'Yearly Setup';
export const loadPell = 'Load Pell';
export const transactionDoc = 'Transaction Documents';
export const portNEWS = 'Portal News';
export const paymentCONFIG = 'Payment Configuration';
export const studentCONFIG = 'Student Configuration';
export const facultyCONFIG = 'Faculty Configuration';
export const applicationCONFIG = 'Application Configuration';
export const miskCONFIG = 'Misc Configuration';
export const ipLock = 'IP Lockout';
export const gradeAppCONFIG = 'Grad App Configuration';
export const tablemainten = 'Table Maintenance';
export const errorLOG = 'View Error Log';
export const rebuildGRADE = 'Grades';
export const enroll = 'Enrollment Counts';
export const isirDirect = 'ISIR Direct'
export const codImport = 'COD Import'
export const codCBImport = 'COD Campus-Based Import'
export const codMessagesImport = 'COD Message'
export const codEdeAwards = 'EDE Awards'
export const powerFaidsImport = 'PowerFaids'
export const RegMaintenance = 'Maintenance'
export const studentAccess = 'Student Access';
export const studentSevis = 'Student SEVIS';
export const laptopTracking = 'Laptop Tracking';
export const evaluationApplicant = 'Applicant';
export const setup = 'Setup';
export const offerings = 'Offerings';
export const studentStatu = 'Student Status';
export const documentTracking = 'Document Tracking';
export const masterCourses = 'Master Courses'
export const offeringMaintenance = 'Offering Maintenance';
export const timeSlotReference = 'Time Slot Reference';
export const tuitionandRefunds = 'Tuition and Refunds';
export const crossListedCourses = 'Cross Listed Courses';
export const Degrees = 'Degrees';
export const lookupLetterSequence = 'Letter Sequences'
export const lookupHighSchool = 'High School'
export const lookupCollege = 'College Names'
export const RegistrationRegister = 'Register';
export const RegisterUnofficial = 'Unofficial';
export const prospectQuestionsId = 'Prospect Questions';
export const prospectMergeId = 'Merge';
export const prospectInternationalId = 'Prospect International';
export const prospectCitizenshipId = 'Prospect Citizenship'
export const ReportstForm = '1098T Form'
export const organizationAdd = 'Add';
export const organizationChange = 'Change';
export const organizationDelete = 'Delete';
export const contactsAdd = 'Add/Change';
export const contactsDelete = 'Delete';
export const changeStudent = "Change Student";
export const addStudent = "Add Student";
export const delete_Student = 'Delete Student'
export const studentRisk = 'Student Risk';
export const examID = 'Generate Exam IDs';
export const semaph = 'Semaphores';
export const registrationSemaph = 'Registration';
export const changeFaculty = 'Change Faculty';
export const addFaculty = 'Add Faculty';
export const deleteFaculty = 'Delete Faculty';
export const facultyMaintenance = 'Faculty Maintenance';
export const exportSpeedeCollegeTranscript = 'Speede College Transcript';
export const dataQueryExport = 'Data Query';
export const ReportConfiguration = 'Report Configuration';
export const ReportstaForm = 'T4A Form'
export const ReporttaForm = 'T2202A Form'
export const enrollement = 'Enrollment'
export const awardLetters = 'Award Letters'
export const reportByor = 'BYOR'
export const trasformationTemplate = 'Email Templates'
export const Schedule = 'Schedule'
export const SsnMigration = 'SSN Migration'
export const publisherSetup = 'Publisher Event Setup'
export const GradeCards = 'Grade Cards'
export const printOffering = 'Print Offering';
export const courseManagementRoles = 'Course Management Roles'
export const Statements = 'Statements'
export const EnrollmentVerification = 'Enrollment Verification'
export const workingToDo = 'Working To-Do'
export const manageForms = 'Tools-Manage Forms'
export const manageProperties = 'Student Life-Properties-Manage'
export const manageAmenities = 'Student Life-Amenities-Manage'
export const ExtensionsSupported = 'image/png,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,text/plain';
export const MAX_FILE_SIZE = 10000000;
export const AccessLevel = {
    NoAccess: 0,
    ReadOnly: 1,
    ReadWrite: 2 
}
export const manageMealPlans = 'Student Life-Meal Plans-Manage'
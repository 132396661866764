
import React from 'react';
import "survey-creator-core/survey-creator-core.min.css";
import "../surveyJS.css";
import "survey-core/defaultV2.css";
import { Divider, IconButton, Typography } from "@mui/material";
import { Box, Button, Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Survey } from 'survey-react-ui';
import * as SurveyJs from "survey-core";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonWrapperFilter } from '../../../../styles/common-styles';
import { customTheme } from '../form_theme';
import { getApi } from '../../../../services/apiBase';
import CloseIcon from '@material-ui/icons/Close';
import { fileAPIs } from "../../../../services/apiURL";

const FormDialog = ({open,onClose,formData,dialogTitle,actions}) => {


    const downloadFile = (survey, options) => {
        
        getApi(`${options.content}`, {responseType: 'blob'})
        .then((response) => response)
        .then((blob) => {
            const file = new File([blob.data], options.fileValue.name, {
                type: options.fileValue.type
            });
            const reader = new FileReader();
            reader.onload = (e) => {
                options.callback("success", e.target.result);
            };
            reader.readAsDataURL(file);                
        })
        .catch((error) => {
            console.error("Error: ", error);
            options.callback("error");
        })
        .finally(
        
        );
    };
        
    const {t} = useTranslation(["tools"]);
    const survey = new SurveyJs.Model(); 
    survey.applyTheme(customTheme);
    survey.onDownloadFile.add(downloadFile);
    survey.questionsOnPageMode ="singlePage";
    survey.mode="display";  
    survey.setJsonObject(formData.jsonObj);
    if (formData?.formResult?.response) {

        // Replace instances of the download URL from Portal with the URL from elements.
        const formDataWithUpdatedURLs = formData?.formResult?.response.replace("ceDownloadHousingDocuments.asp", fileAPIs.file);
        survey.data = JSON.parse(formDataWithUpdatedURLs);
    }

    const formDate = formData?.formResult?.date;
    const formTime = formData?.formResult?.time;
    const formEmail = formData?.formResult?.email;

    return (
    <>       
        <Dialog open={open}
            onClose={onClose}
            maxWidth={'lg'}
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
        >
  
            <DialogTitle id="responsive-dialog-title" style={{paddingTop: "5px"}}>      
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>     
                    <Typography component="h4" variant="h7" style={{ flexGrow: 1, textAlign:'center' }}>{dialogTitle}</Typography>        

                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        style={{
                            float: "right",
                            textAlign: "right",
                            marginRight: -12 
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider style={{margin:"0 -25px"}} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 0,
                        m: 1,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                    }}
                >
                    <div>{t("formLabels.responseDate" , { ns: "tools" })}: {new Date(`${formDate} ${formTime}`).toLocaleDateString()}</div>
                    <div>{t("formLabels.email" , { ns: "tools" })}: {formEmail?? "N/A"}</div>
                    <div>{t("formLabels.responseTime" , { ns: "tools" })}: {new Date(`${formDate} ${formTime}`).toLocaleTimeString()}</div>
                </Box>    
                
                <Divider style={{margin:"0 -25px"}} />
            </DialogTitle>
            
            <DialogContent >
               <Survey model={survey} />               
            </DialogContent>
            <Divider/>
            <DialogActions>
                
                <ButtonWrapperFilter >                 
                {actions?.map((action, index) => (
                    <Button key={index}
                            className={action.className || "BgLightBlue" }
                            variant="contained"
                            size="large"
                            type="reset"
                            aria-label={action.ariaLabel}
                            onClick={action.onClick}>
                        {action.buttonName}
                    </Button>
                ))}
                    
                             
                </ButtonWrapperFilter>          

            </DialogActions>
        </Dialog> 
    </>
    )
}
export default React.memo(FormDialog);

import {
    SET_IPEDS_TAB,
} from './types'

const initialState = {
    ipedsTab: {
        facultyID: 0,
        primaryContactType: 0,
        tenureClassification: 0,
        primaryFunction: 0
    },
};

const ipedsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_IPEDS_TAB:
            return {
                ...state,
                ipedsTab: payload
            };
        
        default:
            return state;
    }
};

export default ipedsReducer;
import { HousingPeriodRoomAssignmentGrid } from '../../../pages/student-life-page/housing/housing-applications/HousingRoomAssignmentGrid';
import {
    BASIC_DETAILS_STEP,
    HOUSING_PERIOD_PROPERTY_OFFERINGS_STEP,
    MANAGE_HOUSING_PERIOD_PROPERTIES_MAIN_VIEW,
    MANAGE_APPLICATIONS_SUBSTEP,
    HOUSING_PERIODS_VIEW,
    PROCESS_APPLICATION_VIEW,
    MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_VIEW,
    MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_NEW,
    MANAGE_CHARGES_SUBSTEP,
    ASSIGN_ROOMS_VIEW,
    ROOM_SEARCH_VIEW
} from '../../../pages/student-life-page/housing/housing-periods/housingPeriodConstants';
import {
    RESET_HOUSING_PERIOD_STATE,
    CLEAR_HOUSING_PERIOD,
    SET_HOUSING_PERIOD_BASIC_DETAILS,
    DELETE_HOUSING_PERIOD,
    DELETE_HOUSING_PERIOD_SUCCESS,
    DELETE_HOUSING_PERIOD_ERROR,
    ENABLE_DISABLE_HOUSING_PERIOD,
    ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS,
    ENABLE_DISABLE_HOUSING_PERIOD_ERROR,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS,
    SEARCH_HOUSING_PERIODS,
    SEARCH_HOUSING_PERIODS_SUCCESS,
    SEARCH_HOUSING_PERIODS_ERROR,
    LOAD_HOUSING_PERIOD,
    LOAD_HOUSING_PERIOD_SUCCESS,
    LOAD_HOUSING_PERIOD_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR,
    SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR,
    GET_PROPERTIES_FOR_HOUSING_PERIOD,
    GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS,
    GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_APPLICATIONS_SUCCESS,
    LOAD_HOUSING_PERIOD_APPLICATIONS_ERROR,
    LOAD_HOUSING_PERIOD_APPLICATIONS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS_SUCCESS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS_ERROR,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS,
    SEARCH_HOUSING_PERIOD_APPLICATIONS_ERROR,
    SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS,
    SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_SUCCESS,
    SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_ERROR,
    LOAD_HOUSING_PROPERTIES_SUCCESS,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_SUCCESS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_ERROR,
    LOAD_MANAGE_CHARGES,
    LOAD_MANAGE_CHARGES_SUCCESS,
    LOAD_MANAGE_CHARGES_ERROR,
    SAVE_MANAGE_CHARGES,
    SAVE_MANAGE_CHARGES_SUCCESS,
    LOAD_MANAGE_CHARGES_SELECT_ITEMS,
    LOAD_MANAGE_CHARGES_SELECT_ITEMS_SUCCESS,
    LOAD_MANAGE_CHARGES_SELECT_ITEMS_ERROR,
    CLEAR_HOUSING_PERIOD_PROPERTY_LIST,
    INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR,
    LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE,
    INITIALIZE_ROOM_ASSIGNMENT_PUBLISH_DATE,
    SET_ROOM_ASSIGNMENT_PUBLISH_DATE,
    SET_MANAGE_ROOM_ASSIGNMENT_PUBLISH_DATE,
    EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE,
    SAVE_EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE,
    SET_HOUSING_PERIOD_BREADCRUMB,
    DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE_IN_MEMORY,
    ADD_HOUSING_PERIOD_PROPERTIES,
    SET_SELECTED_HOUSING_PERIOD_PROPERTY,
    SET_HOUSING_PERIOD_APPLICATION_BASIC_DETAILS,    
} from './housingPeriodsActionTypes';

import {
    LOAD_HOUSING_PERIOD_PROPERTY,
    LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_ERROR,
    CLEAR_HOUSING_PERIOD_PROPERTY,
    SAVE_HOUSING_PERIOD_PROPERTY,
    SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS,
    SAVE_HOUSING_PERIOD_PROPERTY_ERROR
} from "../housing-period-property/housingPeriodPropertyActionTypes";

import {
    NAVIGATE_TO_ROOM_SEARCH
} from "../housing-room-search/housingRoomSearchActionTypes";

import { selectedHousingPeriodProperties } from './housingPeriodsSelectors';


const initialState = {
  selectItems: {
    roommateQuestionnaires: [],
    propertyapplications: [] /*forms of type Application*/,
    moveInRules: [],
    propertyTypes: [],
    amenities: [],
    programs: [],
    yearClassifications: [],
    glossarySports: [],
    genders: [],
    applicationStatus: [],
    bedTypes: [],
    campuses: [],
    housingApplications: [],
    properties: [],
    residentialStatus: [],
    residentialTypes: [],
    roomAmenities: [],
    roomStatuses: [],
    roomTypes: [],
    
  },
  search: {
    isLoading: false,
    results: [],
  },
  activeView: HOUSING_PERIODS_VIEW,
  housingPeriodPropertyApplications: [],
  addEditWizard: {
    isWizardVisible: false,
    activeStep: BASIC_DETAILS_STEP,
    urlPath: "/student-life/housing/housing-periods",
    housingPeriod: {
      housingPeriodID: 0,
      housingPeriodUUID: "",
      housingPeriodName: "",
      startDate: null,
      endDate: null,
      isActive: true,
      description: undefined,
      portalPublishStartDate: null,
      portalPublishEndDate: null,
      moveInRuleIDs: [],
      moveInChecklist: null,
      moveInCheckListName: "",
      roommateQuestionnaireID: null,
      roomAssignmentPublishDates: [],
      roomAssignmentPublishDate: {
        editMode: false,
        residentType: null,
        residentTypeDisplayText: '',
        residentStatus: null,
        residentStatusDisplayText: '',
        roomAssignmentStartDate: null,
        roomAssignmentStartTime: null,
        roomAssignmentStartTimeDisplayText: '',
        roomAssignmentEndDate: null,
        roomAssignmentEndTime: null,
        roomAssignmentEndTimeDisplayText: '',
        cumulativeCredits: undefined
      }
    },
    housingRoomAssignmentPublishDateSubtep: MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_VIEW,
    housingPeriodProperties: [],
    housingPeriodPropertyManageCharges: {
      housingPeriodPropertyID: null,
      housingPeriodID: null,
      securityDepositId: null,
      rentalIncomeId: null,
      depositAmount: null,
      depositType: null,
      paymentCategory: null,
      depositDueOn: null,
      selectedSecurityDepositTransDocId: null,
      selectedRentalIncomeTransDocId: null,
      securityDepositTransDocId: null,
      securityDepositTransactionCategory: null,
      rentalIncomePaymentFrequency: null,
      rentDueOn: null,
      rentalIncomeTransDocId: null,
      rentalIncomeTransactionCategory: null,
      staffDiscount: null,
      isProrated: null,
      rentDueOnRecurringDateForMonthly:null,
      rentDueOnRecurringMonthly:null,
      rentDueOnRecurringDateForYearly:null,
      rentDueOnRecurringMonthForYearly:null,
      rentDueOnRecurringYearly:null,

      

    },
    manageChargesSelectItems: {
      transDocs: [],
      depositTypes: [],
      transactionCategories:[],
      paymentFrequencies:[]
    },
    manageHousingPeriodPropertiesSubstep:
      MANAGE_HOUSING_PERIOD_PROPERTIES_MAIN_VIEW,
    selectedHousingPeriodPropertyID: 0,
    addEditHousingPeriodPropertyApplication: {
      isAddEditHousingPeriodPropertyApplicationVisible: false,
      housingPeriodPropertyApplication: {
        housingPeriodPropertyApplicationID: 0,
        housingPeriodPropertyID: undefined,
        applicationID: undefined,
        residentTypeID: undefined,
        frequencyID: undefined,
        transDocID: undefined,
      },
    },
    },
    addEditWizardAssignRoom: {
        housingPeriodApplication: {
            applicationName: '',
            firstName: '',
            lastName: ''
        }
    },
  breadcrumb: [],
};

const housingPeriodsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case RESET_HOUSING_PERIOD_STATE:
            return { ...initialState };
        case SET_HOUSING_PERIOD_BASIC_DETAILS:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        ...payload
                    }
                }
            };
        case CLEAR_HOUSING_PERIOD:
            return  {
                ...state,
                activeView: HOUSING_PERIODS_VIEW,
                addEditWizard: {
                    ...state.addEditWizard,
                    urlPath: '/student-life/housing/housing-periods',
                    isWizardVisible: false,                    
                    activeStep: BASIC_DETAILS_STEP,
                    housingPeriod: { 
                        ...initialState.addEditWizard.housingPeriod 
                    }
                }
            };
        case DELETE_HOUSING_PERIOD:
        case ENABLE_DISABLE_HOUSING_PERIOD:
        case LOAD_HOUSING_PERIOD_SELECT_ITEMS:
        case SEARCH_HOUSING_PERIODS:
        case LOAD_HOUSING_PERIOD:
        case SAVE_HOUSING_PERIOD_BASIC_DETAILS:
        case INITIALIZE_HOUSING_PERIOD_PROPERTIES:
        case SAVE_HOUSING_PERIOD_PROPERTY:        
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION:        
        case DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION:
        case SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION:
        case LOAD_MANAGE_CHARGES:
        case SAVE_MANAGE_CHARGES:
        case LOAD_MANAGE_CHARGES_SELECT_ITEMS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: true
                }
            };
        case ADD_HOUSING_PERIOD_PROPERTY_APPLICATION:{
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyProperties: payload
                    }
                }
            }
        }
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS:
            return {
                ...state, 
                search: {
                    ...state.search,
                    isLoading: true
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    selectedHousingPeriodProperties: payload,                    
                    urlPath:`/student-life/housing/housing-periods/${state.addEditWizard.housingPeriod.housingPeriodUUID}/applications`
                }                
            }
        case GET_PROPERTIES_FOR_HOUSING_PERIOD:
            return {
                ...state, 
                search: {
                    ...state.search,
                    isLoading: true
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    urlPath:`/student-life/housing/housing-periods/${state.addEditWizard.housingPeriod.housingPeriodUUID}/properties`
                }
            }                     
        case LOAD_HOUSING_PERIOD_PROPERTY:
            return {
                ...state, 
                addEditWizard: {
                    ...state.addEditWizard,
                    selectedHousingPeriodPropertyID:payload,
                    urlPath:`/student-life/housing/housing-periods/${state.addEditWizard.housingPeriod.housingPeriodUUID}/properties/${payload}`
                }
            }                     
        case SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS:
                return {
                    ...state,
                    activeView:PROCESS_APPLICATION_VIEW,
                    search: {
                        ...state.search,
                        isLoading: true
                    },
                    addEditWizard: {
                        ...state.addEditWizard,
                    }
    
                };
        case SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_SUCCESS:
            return {
                ...state,
                activeView:PROCESS_APPLICATION_VIEW,
                search: {
                    ...state.search,
                    results: payload,
                    isLoading: false
                }
            };
        case SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_ERROR:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                }
            };       
        case DELETE_HOUSING_PERIOD_SUCCESS:
            const housingPeriods = state.search.results.filter(x => x.housingPeriodID !== payload);
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                    results: housingPeriods
                }
            }
        case DELETE_HOUSING_PERIOD_ERROR:
        case ENABLE_DISABLE_HOUSING_PERIOD_ERROR:
        case LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR:
        case SEARCH_HOUSING_PERIODS_ERROR:
        case LOAD_HOUSING_PERIOD_ERROR:
        case SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR:
        case INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR:
        case GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR:
        case LOAD_HOUSING_PERIOD_PROPERTY_ERROR:
        case SAVE_HOUSING_PERIOD_PROPERTY_ERROR:
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR:
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
        case ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
        case DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
        case SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
        case LOAD_HOUSING_PERIOD_APPLICATIONS_ERROR:
        case SEARCH_HOUSING_PERIOD_APPLICATIONS_ERROR:
        case LOAD_MANAGE_CHARGES_ERROR:
        case LOAD_MANAGE_CHARGES_SELECT_ITEMS_ERROR:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                }
            };
        case ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS:
            const updatedHousingPeriods = state.search.results.map(hp => {
                if (hp.housingPeriodID === payload) {
                    return {
                        ...hp,
                        isActive: hp.isActive === "Yes" ? "No" : "Yes"
                    }
                }

                return { ...hp }
            });

            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                    results: updatedHousingPeriods
                }
            };
        case LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS:
            return {
                ...state,
                selectItems: payload,
                search: {
                    ...state.search,
                    isLoading: false
                }
            };

        case  LOAD_MANAGE_CHARGES_SELECT_ITEMS_SUCCESS:
         return {
            ...state,
            addEditWizard: {
              ...state.addEditWizard,
              manageChargesSelectItems: payload, 
            },
          };

        case SEARCH_HOUSING_PERIODS_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    results: payload,
                    isLoading: false
                }
            };
        case LOAD_HOUSING_PERIOD_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                
                addEditWizard: {
                    ...state.addEditWizard,
                    urlPath:`/student-life/housing/housing-periods/${payload.housingPeriodUUID}`,
                    isWizardVisible: true,
                    housingPeriod: {                        
                        ...state.addEditWizard.housingPeriod,
                        ...payload 
                    } 
                }
            }
        case INITIALIZE_NEW_HOUSING_PERIOD:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                
                addEditWizard: {
                    ...state.addEditWizard,
                    isWizardVisible: true,
                    urlPath:'/student-life/housing/housing-periods/new',
                    housingPeriod: { ...initialState.addEditWizard.housingPeriod }
                }
            }
        case SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    urlPath:`/student-life/housing/housing-periods/${payload.housingPeriodUUID}`,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        ...payload
                    }
                }
            }
        case SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    manageHousingPeriodPropertiesSubstep: payload
                }
            }
        case INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriodProperties: payload
                }
            }
        case GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,                    
                    activeStep: HOUSING_PERIOD_PROPERTY_OFFERINGS_STEP,
                    housingPeriodProperties: payload
                }
            }
        case SET_SELECTED_HOUSING_PERIOD_PROPERTY:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    manageHousingPeriodPropertiesSubstep: payload.substep,
                    selectedHousingPeriodProperties:payload.selectedProperties,
                }
            }

        case LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    manageHousingPeriodPropertiesSubstep: payload.substep
                }
            }

        case LOAD_MANAGE_CHARGES_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    manageHousingPeriodPropertiesSubstep: payload.substep,
                    housingPeriodPropertyManageCharges: payload.housingPeriodPropertyManageCharges, // Set to payload
                  
                }
            }

            case SAVE_MANAGE_CHARGES_SUCCESS:
                return {
                    ...state,
                    search: {
                        ...state.search,
                        isLoading: false,
                    },
                    addEditWizard: {
                        ...state.addEditWizard,
                        manageHousingPeriodPropertiesSubstep: MANAGE_CHARGES_SUBSTEP
                    }
                }

        case SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard
                }
            }
        case CLEAR_HOUSING_PERIOD_PROPERTY_LIST: 
        return {
            ...state,
            search: {
                ...state.search,
                isLoading: false
            },
            
            addEditWizard: {
                ...state.addEditWizard,
                activeStep: BASIC_DETAILS_STEP,
                urlPath:`/student-life/housing/housing-periods/${state.addEditWizard.housingPeriod.housingPeriodUUID}`,
                isWizardVisible: true,                
                housingPeriod: {                        
                    ...state.addEditWizard.housingPeriod} 
            }
        }
        case CLEAR_HOUSING_PERIOD_PROPERTY:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    urlPath:`/student-life/housing/housing-periods/${state.addEditWizard.housingPeriod.housingPeriodUUID}/properties`,
                    selectedHousingPeriodProperties:undefined,                     
                    manageHousingPeriodPropertiesSubstep: MANAGE_HOUSING_PERIOD_PROPERTIES_MAIN_VIEW,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: false,                        
                    }                    
                }
            }
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,                    
                    housingPeriodPropertyApplications: payload.housingPeriodPropertyApplications,
                    selectedHousingPeriodPropertyID: payload.selectedHousingPeriodPropertyID,
                    manageHousingPeriodPropertiesSubstep: MANAGE_APPLICATIONS_SUBSTEP
                }
            }
        case ADD_HOUSING_PERIOD_PROPERTIES:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    manageHousingPeriodPropertiesSubstep: MANAGE_APPLICATIONS_SUBSTEP,
                    selectedHousingPeriodProperties: payload,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,                        
                        housingPeriodPropertyApplication: {
                            ...initialState.addEditWizard.addEditHousingPeriodPropertyApplication.housingPeriodPropertyApplication,
                            housingPeriodPropertyID: 1,
                            
                        }
                    }
                }
            }            
        case INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPropertyApplicationSaved: false,
                        housingPeriodPropertyApplication: {
                            ...initialState.addEditWizard.addEditHousingPeriodPropertyApplication.housingPeriodPropertyApplication,
                            housingPeriodPropertyID: payload
                        }
                    }
                }
            }
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    urlPath:`/student-life/housing/housing-periods/${state.addEditWizard.housingPeriod.housingPeriodUUID}/applications`,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }
        case SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }
        case CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    urlPath:`/student-life/housing/housing-periods/${state.addEditWizard.housingPeriod.housingPeriodUUID}/properties`,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: false,                        
                        housingPeriodPropertyApplication: { ...initialState.addEditWizard.addEditHousingPeriodPropertyApplication.housingPeriodPropertyApplication }
                    }
                }
            };
        case LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE:
        case INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDates: initialState.addEditWizard.housingPeriod.roomAssignmentPublishDates
                    }
                }
            };

        case DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }
        case ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }
        case LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDates: payload.map((item, index) => ({ ...item, id: index}))
                    }
                }
            }
        case LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR:
        case LOAD_HOUSING_PERIOD_APPLICATIONS_SUCCESS:
            return {                
                ...state,
                housingPeriodApplications: payload                
            };
        case LOAD_HOUSING_PROPERTIES_SUCCESS:
            return {
                ...state,
                housingProperties: payload
            };
        case INITIALIZE_ROOM_ASSIGNMENT_PUBLISH_DATE: 
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingRoomAssignmentPublishDateSubtep: MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_NEW,
                    housingPeriod:{
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDate: initialState.addEditWizard.housingPeriod.roomAssignmentPublishDate,
                    }
                }
            };
        case LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE: 
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDate: payload
                    }
                }
            }
        case SET_ROOM_ASSIGNMENT_PUBLISH_DATE:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingRoomAssignmentPublishDateSubtep: MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_VIEW,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDate: {
                            ...state.addEditWizard.housingPeriod.roomAssignmentPublishDate,
                            ...payload,
                            editMode: false,
                        }
                    }
                }
            }
        case EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingRoomAssignmentPublishDateSubtep: MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_NEW,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDate: {
                            ...state.addEditWizard.housingPeriod.roomAssignmentPublishDate,
                            ...payload,
                            editMode: true
                        }
                    }
                }
            }
        case SET_MANAGE_ROOM_ASSIGNMENT_PUBLISH_DATE:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingRoomAssignmentPublishDateSubtep: payload
                }
            }
        case DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE_IN_MEMORY:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDates: state.addEditWizard.housingPeriod.roomAssignmentPublishDates.filter((item) => payload.some((p) => item?.roomAssignmentPublishDateID !== p?.roomAssignmentPublishDateID))
                    }
                }
            }
        case SAVE_EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE:
            state.addEditWizard.housingPeriod.roomAssignmentPublishDates[payload.id] = payload
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: {
                        ...state.addEditWizard.housingPeriod,
                        roomAssignmentPublishDate: {
                            ...state.addEditWizard.housingPeriod.roomAssignmentPublishDate,
                            ...payload,
                            editMode: false,
                        },
                        roomAssignmentPublishDates: state.addEditWizard.housingPeriod.roomAssignmentPublishDates
                    }
                }
            }
        case SET_HOUSING_PERIOD_BREADCRUMB:
            return {
                ...state,
                breadcrumb: [ ...payload ]
            }
        case LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS:
            {
                return {
                    ...state,
                    activeView: ASSIGN_ROOMS_VIEW
                }
            }
        case LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    results: payload,
                    isLoading: false
                }
            };
        case NAVIGATE_TO_ROOM_SEARCH:
            return {
                ...state,
                activeView: ROOM_SEARCH_VIEW
            }
        case SET_HOUSING_PERIOD_APPLICATION_BASIC_DETAILS:
            return {
                ...state,
                addEditWizardAssignRoom: {
                    ...state.addEditWizardAssignRoom,
                    housingPeriodApplication: {
                        ...state.addEditWizardAssignRoom.housingPeriodApplication,
                        ...payload
                    }
                }
            };
        default:
            return state;
    }
}

export default housingPeriodsReducer;
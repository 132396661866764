export const BASIC_DETAILS_STEP = 1;
export const HOUSING_PERIOD_PROPERTY_OFFERINGS_STEP = 2;

export const MANAGE_HOUSING_PERIOD_PROPERTIES_MAIN_VIEW = 0;
export const MANAGE_HOUSING_PERIOD_PROPERTY_SUBSTEP = 1;
export const MANAGE_APPLICATIONS_SUBSTEP = 2;
export const MANAGE_CHARGES_SUBSTEP = 3;
export const MANAGE_CONTRACTS_DISCLOSURES_SUBSTEP = 4;
export const MANAGE_MOVE_DATES_SUBSTEP = 5;
export const HOUSING_PERIOD_PROCESS_APPLICATIONS = 6;
export const HOUSING_PERIOD_ROOM_ASSIGNMENTS = 7;
export const HOUSING_PERIODS_VIEW = 11;
export const PROCESS_APPLICATION_VIEW = 12;
export const MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_VIEW = 0;
export const MANAGE_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_NEW = 1;
export const APARTMENT_COMPLEX = 1;
export const ASSIGN_ROOMS_VIEW = 13;
export const ROOM_SEARCH_VIEW = 14;
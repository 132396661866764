export const LOAD_HOUSING_PERIOD_PROPERTY = "LOAD_HOUSING_PERIOD_PROPERTY";
export const LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS = "LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS";
export const LOAD_HOUSING_PERIOD_PROPERTY_ERROR = "LOAD_HOUSING_PERIOD_PROPERTY_ERROR";
export const SET_SELECTED_FLOOR = "SET_SELECTED_FLOOR";
export const SET_SELECTED_UNIT = "SET_SELECTED_UNIT";
export const SET_SELECTED_ROOM = "SET_SELECTED_ROOM";
export const SET_PROPERTY_FIELD = "SET_PROPERTY_FIELD";
export const SET_FLOOR_FIELD = "SET_FLOOR_FIELD";
export const SET_UNIT_FIELD = "SET_UNIT_FIELD";
export const SET_ROOM_FIELD = "SET_ROOM_FIELD";
export const CLEAR_HOUSING_PERIOD_PROPERTY = "CLEAR_HOUSING_PERIOD_PROPERTY";
export const SAVE_HOUSING_PERIOD_PROPERTY = "SAVE_HOUSING_PERIOD_PROPERTY";
export const SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS = "SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS";
export const SAVE_HOUSING_PERIOD_PROPERTY_ERROR = "SAVE_HOUSING_PERIOD_PROPERTY_ERROR";
import {
    NAVIGATE_TO_ROOM_SEARCH,
    SEARCH_ROOMS,
    SEARCH_ROOMS_SUCCESS,
    SEARCH_ROOMS_ERROR,
    ASSIGN_ROOM,
    ASSIGN_ROOM_SUCCESS,
    ASSIGN_ROOM_ERROR,
    SEARCH_ROOMS_RESET_FORM
} from "./housingRoomSearchActionTypes";
import {
    postApi
} from "../../../services/apiBase";
import { housingAPIs } from "../../../services/apiURL";

export const searchRooms = (searchCriteria) => async (dispatch) => {
    dispatch({ type: SEARCH_ROOMS });

    try {
        const response = await postApi(`${housingAPIs.housingPeriods.housingPeriodRoomAssignments.roomSearch}`, searchCriteria);
        const results = response.data.data;
        dispatch({ type: SEARCH_ROOMS_SUCCESS, payload: results });
    }
    catch (err) {
        dispatch({ type: SEARCH_ROOMS_ERROR });
        console.error(err);
    }
}

export const assignRoom = () => async (dispatch) => {
    dispatch({ type: ASSIGN_ROOM });

    try {
        const response = await postApi(`${housingAPIs.housingPeriods.housingPeriodRoomAssignments.assignRoom}`);
        const results = response.data.data;
        dispatch({ type: ASSIGN_ROOM_SUCCESS, payload: results });
    }
    catch (err) {
        dispatch({ type: ASSIGN_ROOM_ERROR });
        console.error(err);

    }
}

export const resetSearch = () => async (dispatch) => {
    dispatch({ type: SEARCH_ROOMS_RESET_FORM });
}

export const navigateToRoomSearch = () => async (dispatch) => {
    dispatch({ type: NAVIGATE_TO_ROOM_SEARCH })
}
import React, { useState, useEffect } from "react";
import { Button, Card, CardContent } from "@material-ui/core";
import { useFormik } from "formik";
import { SelectInput, TextInput, CheckBoxInput, DateInput } from "../../../../components/Forms";
import { FormGroup, ButtonWrapperFilter, useStyles, Splitter, } from "../../../../styles/common-styles";
import { useTranslation } from "react-i18next";
import HeaderTitle from "../../../../utils/HeaderTitle/HeaderTitle";
import { financialAid_financialaid } from "../../../../services/navigation_folder/financialAid";
import PopupHeader from "../../../common-page/popupHeader";
import Tooltips from "../../../../utils/Tooltip/Tooltip";
import { getApi, postApi } from '../../../../services/apiBase';
import { toastMessage } from '../../../../utils/Message/toasts';
import { award_detail_post, award_select_items, cod_award_select_items } from "../../../../services/apiURL";
import * as yup from 'yup';

const AddFinancialAidAwardDetailEntry = ({ onClose, selectedRow, selectedSelected, accessFlag }) => {
  const { t } = useTranslation([
    "translation",
    "financialAid",
    "financialAidHeadcells",
  ]);
  const classess = useStyles();
  const [formValues, setFormValues] = useState({
    awardTypeID: 0,
    financialAwardNumber: 0,
    campusID: 0,
    codFinancialAward: '',
    studentEligibilityCodeID: 0,
    abilityToBenefitTestAdministratorCodeID: 0,
    abilitytoBenefitTestCodeID: 0,
    abilityToBenefitCompletionDate: null,
    stateID: 0,
    programOfStudyID: 0,
    preprofessionalCourseworkIndicator: true,
    originationFeePercent: '',
    interestRebatePercent: '',
    academicYearBeginDate: null,
    academicYearEndDate: null,
    financialAidYearBeginDate: null,
    financialAidYearEndDate: null,
    weeksProgramsAcademicYear: '',
    codAwardType: ''
  });

    
    const validationSchema = yup.object({
        abilitytoBenefitTestCodeID: yup
            .string()
            .when("studentEligibilityCodeID", {
                is: (value) => value === "01" || value === "12",
                then: yup
                    .string()
                    .required(t("validationMessages.isRequired", { what: t("commonLabel.abilitytoBenefitTestCode", { ns: "financialAid" }) }))
            }),
        abilityToBenefitTestAdministratorCodeID: yup
            .string()
            .when("studentEligibilityCodeID", {
                is: (value) => value === "01" || value === "12",
                then: yup
                    .string()
                    .required(t('validationMessages.isRequired', { what: t("commonLabel.abilitytoBenefitTestAdministrator", { ns: "financialAid" }) }))
            }),
        abilityToBenefitCompletionDate: yup
            .date()
            .nullable()
            .when("studentEligibilityCodeID", {
                is: (value) => value === "01" || value === "12",
                then: yup
                    .date()
                    .nullable()
                    .required(t('validationMessages.isRequired', { what: t("commonLabel.abilitytoBenefitCompletionDate", { ns: "financialAid" }) }))
            })
    });


  const [dropDown, setDropDown] = useState({
    "campuses": [],
    "studentEligibilityCodes": [],
    "administratorCodes": [],
    "benefitTestCodes": [],
    "stateCodes": [],
    "studyPrograms": [],
  })
  const [dropdown, setDropdown] = useState({
    "awardType": []
  })
  const formik = useFormik({
    initialValues: formValues,
      enableReinitialize: true,
      validationSchema: validationSchema,
    onSubmit: (values) => {
      const payloadRequest = {
        financialHeaderID: (selectedRow.financialHeaderID) ? selectedRow.financialHeaderID : 0,
        financialStatusID: (selectedRow.financialStatusID) ? selectedRow.financialStatusID : 0,
        awardTypeID: values.awardTypeID,
        financialAwardNumber: values.financialAwardNumber,
        codFinancialAwardID: values.codFinancialAward,
        abilityToBenefitCode: values.studentEligibilityCodeID,
        abilityToBenefitTestAdministratorCode: values.abilityToBenefitTestAdministratorCodeID,
        abilityToBenefitTestCode: values.abilitytoBenefitTestCodeID,
        abilityToBenefitCompletionDate: values.abilityToBenefitCompletionDate,
        abilityToBenefitStateID: values.stateID,
        programOfStudy: values.programOfStudyID,
        preprofessionalCourseworkIndicator: values.preprofessionalCourseworkIndicator,
        derivedAbilityToBenefit: (selectedRow.derivedAbilityToBenefit) ? selectedRow.derivedAbilityToBenefit : false,
        originationFeePercent: values.originationFeePercent,
        interestRebatePercent: values.interestRebatePercent,
        financialAidYearBeginDate: values.financialAidYearBeginDate,
        financialAidYearEndDate: values.financialAidYearEndDate,
        academicYearBeginDate: values.academicYearBeginDate,
        academicYearEndDate: values.academicYearEndDate,
        weeksProgramsAcademicYear: Number(values.weeksProgramsAcademicYear),
        campusID: values.campusID
      }
      postApi(`${award_detail_post}`, payloadRequest).then(resp => {
        toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullyUpdated'));
        onClose();
      }).catch(error => {
        console.error(error);
      });
    }
  })
  useEffect(() => {
    getApi(`${cod_award_select_items}`).then((resp) => {
      const { campuses, studentEligibilityCodes, administratorCodes, benefitTestCodes, stateCodes, studyPrograms, defaultCampusID } = resp.data.data;
      setDropDown({
        ...dropDown,
        "campuses": campuses,
        "studentEligibilityCodes": studentEligibilityCodes,
        "administratorCodes": administratorCodes,
        "benefitTestCodes": benefitTestCodes,
        "stateCodes": stateCodes,
        "studyPrograms": studyPrograms,
      })
    }).catch((err) => {
      console.error(err);
    })
    getApi(`${award_select_items}`).then((res) => {
      const { awardType } = res.data.data;
      setDropdown({
        ...dropdown,
        "awardType": awardType,
      })
    }).catch((err) => {
      console.error(err);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (selectedRow !== '') {
      setFormValues({
        ...formValues,
        awardTypeID: selectedRow.awardTypeID,
        financialAwardNumber: selectedRow.financialAwardNumber,
        originationFeePercent: selectedRow.originationFeePercent,
        codFinancialAward: selectedRow.codFinancialAwardID,
        interestRebatePercent: selectedRow.interestRebatePercent,
        campusID: selectedRow.campusID,
        stateID: selectedRow.abilityToBenefitStateID,
        preprofessionalCourseworkIndicator: selectedRow.preprofessionalCourseworkIndicator,
        studentEligibilityCodeID: selectedRow.abilityToBenefitCode,
        abilityToBenefitTestAdministratorCodeID: selectedRow.abilityToBenefitTestAdministratorCode,
        abilitytoBenefitTestCodeID: selectedRow.abilityToBenefitTestCode,
        abilityToBenefitCompletionDate: selectedRow.abilityToBenefitCompletionDate,
        programOfStudyID: selectedRow.programOfStudy,
        academicYearBeginDate: selectedRow.academicYearBeginDate,
        academicYearEndDate: selectedRow.academicYearEndDate,
        financialAidYearBeginDate: selectedRow.financialAidYearBeginDate,
        financialAidYearEndDate: selectedRow.financialAidYearEndDate,
        weeksProgramsAcademicYear: selectedRow.weeksProgramsAcademicYear,
        codAwardType: selectedRow.codAwardType
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow])
  React.useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("popup-Page");
  }, []);

    const showMandatoryIndicator = formik.values.studentEligibilityCodeID === "01" || formik.values.studentEligibilityCodeID === "12";
    
  return (
    <>
      <PopupHeader
        title=

        {(selectedRow === '') ?
          t("commonLabel.addFinancialAidAwardDetailEntry", {
            ns: "financialAid",
          })
          : t("commonLabel.updateFinancialAidAwardDetailEntry", {
            ns: "financialAid",
          })
        }
        navigationList={financialAid_financialaid}
        onClose={onClose}
      />
      <form className={classess.root} onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.codAwardHeaderDetail", { ns: "financialAid" })} />
            <Splitter height={"10px"} />
            <FormGroup>
              <SelectInput
                disabled
                isDoubled
                label={t("commonLabel.awardType", { ns: "financialAid" })}
                labelId="awardTypeID"
                formikValue={formik.values.awardTypeID}
                formikChange={formik.handleChange}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropdown.awardType }}
              />
              <TextInput
                disabled
                isDoubled
                label={t("commonLabel.financialAwardNumber", { ns: "financialAid" })}
                labelId="financialAwardNumber"
                formikValue={formik.values.financialAwardNumber}
                formikChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                isDoubled
                label={t("commonLabel.campus", { ns: "financialAid" })}
                labelId="campusID"
                formikValue={formik.values.campusID}
                disabled={accessFlag}
                formikChange={formik.handleChange}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.campuses }}
              />
              <TextInput
                isDoubled
                label={t("commonLabel.codFinancialAwardID", { ns: "financialAid" })}
                labelId="codFinancialAward"
                disabled={true}
                formikValue={formik.values.codFinancialAward}
                formikChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                isDoubled
                label={t("commonLabel.studentEligibilityCode", { ns: "financialAid" })}
                labelId="studentEligibilityCodeID"
                formikValue={formik.values.studentEligibilityCodeID}
                formikChange={formik.handleChange}
                disabled={accessFlag}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.studentEligibilityCodes }}
              />
              <SelectInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.abilitytoBenefitTestAdministrator", { ns: "financialAid" })}
                labelId="abilityToBenefitTestAdministratorCodeID"
                formikValue={formik.values.abilityToBenefitTestAdministratorCodeID}
                formikChange={formik.handleChange}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.administratorCodes }}
                errors={formik.errors.abilityToBenefitTestAdministratorCodeID}
                touched={formik.touched.abilityToBenefitTestAdministratorCodeID}
                mandatory={showMandatoryIndicator}
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.abilitytoBenefitTestCode", { ns: "financialAid" })}
                labelId="abilitytoBenefitTestCodeID"
                formikValue={formik.values.abilitytoBenefitTestCodeID}
                formikChange={formik.handleChange}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.benefitTestCodes }}
                errors={formik.errors.abilitytoBenefitTestCodeID}
                touched={formik.touched.abilitytoBenefitTestCodeID}
                mandatory={showMandatoryIndicator}
              />
              <DateInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.abilitytoBenefitCompletionDate", { ns: "financialAid" })}
                labelId={"abilityToBenefitCompletionDate"}
                defaultValue={formik.values.abilityToBenefitCompletionDate}
                formik={formik}
                errors={formik.errors.abilityToBenefitCompletionDate}
                touched={formik.touched.abilityToBenefitCompletionDate}
                mandatory={showMandatoryIndicator}
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.state", { ns: "financialAid" })}
                labelId="stateID"
                formikValue={formik.values.stateID}
                formikChange={formik.handleChange}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.stateCodes }}
              />
              <SelectInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.programofStudy", { ns: "financialAid" })}
                labelId="programOfStudyID"
                formikValue={formik.values.programOfStudyID}
                formikChange={formik.handleChange}
                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: dropDown.studyPrograms }}
              />
            </FormGroup>
            <FormGroup>
              <CheckBoxInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.preProfessionalCourseworkIndicator", { ns: "financialAid" })}
                labelId={"preprofessionalCourseworkIndicator"}
                formikValue={formik.values.preprofessionalCourseworkIndicator}
                formikChange={formik.handleChange}
                checked={formik.values.preprofessionalCourseworkIndicator}
              />
              <TextInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.originationFeePercent", { ns: "financialAid" })}
                labelId="originationFeePercent"
                formikValue={formik.values.originationFeePercent}
                formikChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                isDoubled
                disabled={accessFlag}
                label={t("commonLabel.interestRebatePercent", { ns: "financialAid" })}
                labelId="interestRebatePercent"
                formikValue={formik.values.interestRebatePercent}
                formikChange={formik.handleChange}
              />
            </FormGroup>
            <Splitter height={"10px"} />
            <HeaderTitle variant={'subHeading'} headerTitle={t("commonLabel.academicandFinancialAidYearBeginandEndDates", { ns: "financialAid" })} />
            <Splitter height={"10px"} />
            <FormGroup>
              <DateInput
                label={t("commonLabel.academicYearBeginDate", { ns: "financialAid" })}
                labelId={"academicYearBeginDate"}
                disabled={accessFlag || ['DLSubsidized', 'DLUnsubsidized', 'DLPLUS', 'DLGraduatePLUS'].indexOf(formik.values.codAwardType) === -1}
                defaultValue={formik.values.academicYearBeginDate}
                formik={formik}
              />
              <DateInput
                label={t("commonLabel.academicYearEndDate", { ns: "financialAid" })}
                labelId={"academicYearEndDate"}
                disabled={accessFlag || ['DLSubsidized', 'DLUnsubsidized', 'DLPLUS', 'DLGraduatePLUS'].indexOf(formik.values.codAwardType) === -1}
                defaultValue={formik.values.academicYearEndDate}
                formik={formik}
              />
              <DateInput
                label={t("commonLabel.financialAidYearBeginDate", { ns: "financialAid" })}
                labelId={"financialAidYearBeginDate"}
                disabled={accessFlag || ['DLSubsidized', 'DLUnsubsidized', 'DLPLUS', 'DLGraduatePLUS'].indexOf(formik.values.codAwardType) === -1}
                defaultValue={formik.values.financialAidYearBeginDate}
                formik={formik}
              />
              <DateInput
                label={t("commonLabel.financialAidYearEndDate", { ns: "financialAid" })}
                labelId={"financialAidYearEndDate"}
                disabled={accessFlag || ['DLSubsidized', 'DLUnsubsidized', 'DLPLUS', 'DLGraduatePLUS'].indexOf(formik.values.codAwardType) === -1}
                defaultValue={formik.values.financialAidYearEndDate}
                formik={formik}
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                label={t("commonLabel.weeksinAcademicYear", { ns: "financialAid" })}
                labelId="weeksProgramsAcademicYear"
                disabled={accessFlag}
                formikValue={formik.values.weeksProgramsAcademicYear}
                formikChange={formik.handleChange}
              />
            </FormGroup>
          </CardContent>
          <CardContent>
            <div style={{ display: "flex" }}>
              <Tooltips />
              <ButtonWrapperFilter>
                <Button disabled={accessFlag} aria-label={t('buttonLabel.add')} className='BgYellowOrange search' variant="contained" type="submit">
                  {(selectedRow === '') ? t('buttonLabel.add') : t('buttonLabel.update')}
                </Button>
                <Button
                  aria-label={"Cancel"}
                  className="BgLightBlue"
                  variant="contained"
                  size="large"
                  onClick={() => onClose("")}
                >
                  {t("buttonLabel.cancel", { ns: "financialAid" })}
                </Button>
              </ButtonWrapperFilter>
            </div>
          </CardContent>
        </Card>
      </form>
    </>
  );
};
export default AddFinancialAidAwardDetailEntry; 
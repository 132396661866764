import {
    SEARCH_ROOMS_RESET_FORM,
    SEARCH_ROOMS_SUCCESS
} from "./housingRoomSearchActionTypes";

const initialState = {
    roomSearchResults: [],
    search: {
        campusId: null,
        propertyId: null,
        propertyTypeId: null,
        roomTypeId: null,
        roomStatus: 1,
        ageOperator: 'Between',
        ageRangeMax:null,
        ageRangeMin:null,
        programId: 0,
        gender: null,
        yearClassification: null,
        residentType: null,
        amenities: [],
        housingStaff: false,
        transferId: null,
        gpaOperator: 'GreaterThanOrEqual',
        gpaMaxValue: null,
        gpaMinValue: null,
        athleticsId: null,
        wheelchairAccesible: null,
        housingRateOperator: 'Between',
        housingRateRangeMax: null,
        housingRateRangeMin: null,
        roommateQuestionnaire: null
    }
    
};

const housingRoomSearchReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEARCH_ROOMS_SUCCESS:
            return {
                ...state,
                roomSearchResults: payload
            }
        case SEARCH_ROOMS_RESET_FORM: 
        debugger;
            return { ...initialState }
        default:
            return state;
    }
}

export default housingRoomSearchReducer;
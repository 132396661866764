export const downloadTextFile = (fileName, fileData) => {
    // Create a Blob object containing the text data
    const blob = new Blob([fileData], { type: 'text/plain' });
    
    // Create an object URL for the Blob
    const objectURL = URL.createObjectURL(blob);

    // Create an <a> element to trigger the download
    const link = document.createElement('a');
    link.href = objectURL;  // Set the href to the Blob's object URL
    link.download = fileName;
    link.click();   // Trigger the download by clicking the link
    // Optional: Revoke the object URL after download to free up memory
    URL.revokeObjectURL(objectURL);
    
}
const getKey = (name) => process.env[`REACT_APP_${name}`];
const getCurrentEnv = () =>process.env[`REACT_APP_CURR_ENV`];

const config = {
    common: {
        base_admission_url: getKey('BASE_ADMISSION_URL'),
        base_api_url: getKey('BASE_LOGIN_URL'),
        build_version: getKey('BUILD_VERSION'),
        integrationApiSwaggerURL: getKey('INTEGRATION_API_SWAGGER_URL')
    },
    credentials: {
        tokenRefreshInMin: getKey('TOKEN_REFRESH_IN_MIN')
    },
    ids: {
        sts_authority: getKey('STS_AUTHORITY'),
        client_id: getKey('CLIENTID'),
        clientRoot: getKey('CLIENTROOT'),
        clientScope : getKey('CLIENTSCOPE'),
    },
    /// azureDetails:{
    ///     redirectURL: getKey('AZURE_REDIRECT_URL')
    /// },
    powerBI: {
        byorUrl: getKey('BYOR_POWERBI_URL'),
        admissionByorUrl: getKey('ADIMISSION_BYOR_POWERBI_URL'),
        admissionDocUrl: getKey('ADMISSION_DOCUMENT_POWERBI_URL'),
        regGradeUrl: getKey('REG_GRADE_SUMMARY_POWERBI_URL'),
        regDirectoryUrl: getKey('REG_DIRECTORY_POWERBI_URL'),
        regFullTimeEqvUrl: getKey('REG_FULL_TIME_EQUIVALENT_POWERBI_URL'),
        regHoldDegreeUrl: getKey('REG_HOLD_DEGREE_REPORTS_POWERBI_URL'),
        billingByorUrl: getKey('BILLING_BYOR_POWERBI_URL'),
        finAidByorUrl: getKey('FIN_AID_BYOR_POWERBI_URL'),
        finAidFisapUrl: getKey('FIN_AID_FISAP_POWERBI_URL'),
    },
    currentEnv:getCurrentEnv()
};

export default config;